import { Route } from '@angular/router';

import { authGuard } from '@innership-app/core';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@innership-app/web-feature-auth').then((m) => m.webFeatureAuthRoutes)
  }, {
    path: 'invoices',
    canActivate: [authGuard],
    loadChildren: () =>
      import('@innership-app/web-feature-invoices').then((m) => m.webFeatureInvoicesRoutes)
  }, {
    path: 'maintenance',
    loadComponent: () => import('@innership-app/web-ui').then((c) => c.MaintenanceComponent)
  }, {
    path: 'projects',
    canActivate: [authGuard],
    loadChildren: () =>
      import('@innership-app/web-feature-projects').then((m) => m.webFeatureProjectsRoutes)
  }, {
    path: 'reactivate-account',
    loadComponent: () => import('@innership-app/web-ui').then((c) => c.ReactivateAccountComponent)
  }, {
    path: '**',
    redirectTo: 'projects'
  }
];
