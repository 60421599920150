import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { environment } from '@innership-app/core';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

defineCustomElements(window);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
