import { ApplicationConfig } from '@angular/core';
import { provideRouter, RouteReuseStrategy, withRouterConfig} from '@angular/router';

import { appSharedConfig, CustomRouteReuseStrategy } from '@innership-app/core';

import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    ...appSharedConfig.providers,
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    provideRouter(appRoutes, withRouterConfig({ paramsInheritanceStrategy: 'always' }))
  ]
};
