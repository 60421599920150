import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { register } from 'swiper/element/bundle';

register();

@Component({
  standalone: true,
  imports: [
    RouterOutlet
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor() {
    if ('serviceWorker' in navigator && navigator.serviceWorker) {
      navigator.serviceWorker.register('firebase-messaging-sw.js')
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(() => {
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {
        });
    }
  }
}
